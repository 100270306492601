<template>
  <MDBCard class="m-3 " :class="{ 'd-none':!Model }">
    <MDBCardBody>
      <form class="form" @submit="Submit">
        <MDBCardHeader v-if="(Action == 'insert')">{{ $t("MEMBER-LIST.ADD-ITEM") }}</MDBCardHeader>
        <MDBCardHeader v-else-if="(Action == 'update')">{{ $t("MEMBER-LIST.EDIT-ITEM") }}</MDBCardHeader>
        <MDBCardText class="pt-3">
          <MDBRow class="g-3">
            <MDBCol md="12">
              <MDBRow class="mb-3">
                <MDBCol md="3">
                  <div>
                    <label class="form-label text-dark" :data-tag="`${Action}-country`">國家<span class="text-danger">*</span></label>  
                  </div>
                  <select class="form-select" v-model="Form.country.value" >
                    <option value="" selected>請選擇國家</option>
                    <template v-for="(c, k) in getCountry" :key="k">
                      <option v-bind:value="k">{{ c }}</option>  
                    </template>
                  </select>
                  <span class="text-danger">{{ countryError }}</span>
                </MDBCol>
              </MDBRow>
              <MDBRow class="mb-3">
                <MDBCol md="3">
                  <div>
                    <label class="form-label text-dark" :data-tag="`${Action}-lang`">常用語系<span class="text-danger">*</span></label>  
                  </div>
                  <select class="form-select" v-model="Form.lang.value" >
                    <option value="" selected>請選擇語系</option>
                    <template v-for="(item, k) in getLangList" :key="k">
                      <option v-bind:value="item.lang">{{ item.name }}</option>  
                    </template>
                  </select>
                  <span class="text-danger">{{ langError }}</span>
                </MDBCol>
              </MDBRow>
              <MDBRow class="mb-3">
                <MDBCol md="3" class="mb-2">
                  <div>
                    <label class="form-label text-dark"  :data-tag="`${Action}-last_name`">姓<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" placeholder="姓" v-model="Form.last_name.value">
                    <span class="text-danger">{{ last_nameError }}</span>
                  </div>
                </MDBCol>
                <MDBCol md="3" class="mb-2">
                  <div>
                    <label class="form-label text-dark"  :data-tag="`${Action}-first_name`">名<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" placeholder="名" v-model="Form.first_name.value">
                    <span class="text-danger">{{ first_nameError }}</span>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow class="mb-3" v-if="(Action == 'insert')">
                <MDBCol md="6" class="mb-2">
                  <div>
                    <label class="form-label text-dark" :data-tag="`${Action}-email`">{{ $t('COMMON.EMAIL') }}<span class="text-danger">*</span></label>
                    <input type="email" class="form-control" name="email" :placeholder="$t('COMMON.EMAIL')" v-model="Form.email.value">
                    <span class="text-danger">{{ emailError }}</span>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow class="mb-3">
                <MDBCol md="6" class="mb-2">
                  <div>
                    <label class="form-label text-dark">手機</label>
                    <input type="text" class="form-control" placeholder="手機" v-model="Form.phone.value">
                    <span class="text-danger">{{ phoneError }}</span>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow class="mb-3" v-if="(Action == 'update')">
                <MDBCol md="6" class="mb-2">
                  <div>
                    <label class="form-label text-dark">聯絡電話</label>
                    <input type="text" class="form-control" placeholder="聯絡電話" v-model="Form.telephone.value">
                    <span class="text-danger">{{ telephoneError }}</span>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow class="mb-3" v-if="(Action == 'update')">
                <MDBCol md="6" class="mb-2">
                  <div>
                    <label class="form-label text-dark">出生年月日</label>
                    <input type="date" class="form-control" placeholder="出生年月日" v-model="Form.birthday.value" format="YYYY-MM-DD">
                    <span class="text-danger">{{ birthdayError }}</span>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow class="mb-3" v-if="(Action == 'update')">
                <MDBCol md="6" class="mb-2">
                  <div>
                    <div>
                      <label for="exampleFormControlInput1" class="form-label text-dark">{{ $t('COMMON.SEX') }}</label>  
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="sex" v-bind:id="`${Action}-sex-1`" value="1" v-model="Form.sex.value">
                      <label class="form-check-label" v-bind:for="`${Action}-sex-1`">
                        {{ $t('COMMON.MAN') }}
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="sex" v-bind:id="`${Action}-sex-0`" value="0" v-model="Form.sex.value">
                      <label class="form-check-label" v-bind:for="`${Action}-sex-0`">
                        {{ $t('COMMON.WOMAN') }}
                      </label>
                    </div>
                    <span class="text-danger">{{ sexError }}</span>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow class="mb-3" v-if="(Action == 'update')">
                <MDBCol md="6">
                  <div>
                    <label class="form-label text-dark">行業別</label>  
                  </div>
                  <select class="form-select" v-model="Form.industry.value" >
                    <option value="" selected>請選擇行業</option>
                    <template v-for="(item, k) in getIndustry" :key="k">
                      <option v-bind:value="item.id">{{ item.name[getLang] }}</option>  
                    </template>
                  </select>
                  <span class="text-danger">{{ industryError }}</span>
                </MDBCol>
              </MDBRow>
              <!-- <MDBRow class="mb-3" v-if="(Action == 'update')">
                <MDBCol md="6">
                  <div>
                    <label class="form-label text-dark">職業別</label>  
                  </div>
                  <select class="form-select" v-model="Form.profession.value" >
                    <option value="" selected>請選擇職業</option>
                    <template v-for="(item, k) in getProfession" :key="k">
                      <option v-bind:value="item.id">{{ item.name[getLang] }}</option>  
                    </template>
                  </select>
                  <span class="text-danger">{{ professionError }}</span>
                </MDBCol>
              </MDBRow> -->
              <MDBRow class="mb-3">
                <MDBCol md="6">
                  <div>
                    <label class="form-label text-dark">公司/學校名稱</label>
                    <input type="text" class="form-control" placeholder="公司/學校名稱" v-model="Form.unit.value" >
                    <span class="text-danger">{{ unitError }}</span>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow class="mb-3" v-if="(Action == 'update')">
                <MDBCol md="6">
                  <div>
                    <label class="form-label text-dark">職稱</label>
                    <input type="text" class="form-control" placeholder="職稱" v-model="Form.title.value" >
                    <span class="text-danger">{{ titleError }}</span>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow class="mb-3" v-if="(Action == 'update')">
                <MDBCol md="6">
                  <div>
                    <label class="form-label text-dark">網站</label>
                    <input type="text" class="form-control" placeholder="網站" v-model="Form.website.value" >
                    <span class="text-danger">{{ websiteError }}</span>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow class="mb-3" v-if="(Action == 'update')">
                <MDBCol md="6" >
                  <div>
                    <label class="form-label text-dark">常用通訊APP</label>
                    <MDBRow class="mb-3" >
                      <MDBCol md="6" >
                        <select class="form-select" v-model="Form.app.value" >
                          <option value="" selected>請選擇APP</option>
                          <template v-for="(item, k) in getApp" :key="k">
                            <option v-bind:value="item.id">{{ item.name }}</option>  
                          </template>
                        </select>
                        <span class="text-danger">{{ appError }}</span>
                      </MDBCol>
                      <MDBCol md="6" >
                        <input type="text" class="form-control" placeholder="帳號" v-model="Form.account.value" >
                        <span class="text-danger">{{ accountError }}</span>
                      </MDBCol>
                    </MDBRow>
                    
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow class="mb-3" v-if="(Action == 'update')">
                <MDBCol md="12" >
                  <div>
                    <label class="form-label text-dark">地區</label>
                    <MDBRow class="mb-3" >
                      <MDBCol md="4" >
                        <input type="text" class="form-control" placeholder="省" v-model="Form.province.value" >
                        <span class="text-danger">{{ provinceError }}</span>
                      </MDBCol>
                      <MDBCol md="4" >
                        <input type="text" class="form-control" placeholder="市" v-model="Form.city.value" >
                        <span class="text-danger">{{ cityError }}</span>
                      </MDBCol>
                      <MDBCol md="4" >
                        <input type="text" class="form-control" placeholder="鄉鎮" v-model="Form.area.value" >
                        <span class="text-danger">{{ areaError }}</span>
                      </MDBCol>
                    </MDBRow>
                    
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow class="mb-3">
                <MDBCol md="6">
                  <div>
                    <label class="form-label text-dark">郵遞區號</label>
                    <input type="text" class="form-control" placeholder="郵遞區號" v-model="Form.postal_code.value" >
                    <span class="text-danger">{{ postal_codeError }}</span>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow class="mb-3" v-if="(Action == 'update')">
                <MDBCol md="12">
                  <div>
                    <label class="form-label text-dark">詳細地址</label>
                    <input type="text" class="form-control" placeholder="詳細地址" v-model="Form.address.value" >
                    <span class="text-danger">{{ addressError }}</span>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow class="mb-3">
                <MDBCol md="6" class="mb-2">
                  <div>
                    <label class="form-label text-dark" :data-tag="`${Action}-password`">{{ $t('COMMON.PASSWORD') }}<span class="text-danger" v-if="(Action == 'insert')">*</span></label>
                    <input type="password" class="form-control" name="password" :placeholder="$t('COMMON.PASSWORD')" v-model="Form.password.value">
                    <span class="text-danger">{{ passwordError }}</span>
                  </div>
                </MDBCol>
                <MDBCol md="6" class="mb-2">
                  <div>
                    <label class="form-label text-dark" :data-tag="`${Action}-password_confirmation`">{{ $t('COMMON.PASSWORDCONFIRM') }}<span class="text-danger" v-if="(Action == 'insert')">*</span></label>
                    <input type="password" class="form-control" name="password_confirmation" :placeholder="$t('COMMON.PASSWORDCONFIRM')" v-model="Form.password_confirmation.value">
                    <span class="text-danger">{{ password_confirmationError }}</span>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow class="mb-3">
                <MDBCol md="6">
                  <div>
                    <label class="form-label text-dark">推薦編號</label>
                    <input type="text" class="form-control" name="refer" placeholder="推薦編號" v-model="Form.refer.value">
                    <span class="text-danger">{{ referError }}</span>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow class="mb-3" v-if="Action == 'update'">
                <MDBCol md="12" >
                  <div>
                    <div>
                      <label for="exampleFormControlInput1" class="form-label text-dark">{{ $t('COMMON.STATUS') }}<span class="text-danger">*</span></label>  
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="status" v-bind:id="`${Action}-status-1`" value="1" v-model="Form.status.value">
                      <label class="form-check-label" v-bind:for="`${Action}-status-1`">
                        {{ $t('COMMON.ENABLE') }}
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="status" v-bind:id="`${Action}-status-0`" value="0" v-model="Form.status.value">
                      <label class="form-check-label" v-bind:for="`${Action}-status-0`">
                        {{ $t('COMMON.DISABLE') }}
                      </label>
                    </div>
                    <span class="text-danger">{{ statusError }}</span>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow class="mb-3">
                <MDBCol md="12" class="mb-2">
                  <div class="mb-3">
                    <div>
                      <label for="exampleFormControlInput1" class="form-label text-dark">{{ $t('COMMON.DEALERSTATUS') }}<span class="text-danger">*</span></label>  
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="dealer" v-bind:id="`${Action}-dealer-1`" value="1" v-model="Form.dealer.value">
                      <label class="form-check-label" v-bind:for="`${Action}-dealer-1`">
                        {{ $t('COMMON.YES') }}
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="dealer" v-bind:id="`${Action}-dealer-0`" value="0" v-model="Form.dealer.value">
                      <label class="form-check-label" v-bind:for="`${Action}-dealer-0`">
                        {{ $t('COMMON.NO') }}
                      </label>
                    </div>
                    <span class="text-danger">{{ dealerError }}</span>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow class="mb-3" v-if="Action == 'update'">
                <MDBCol md="12" class="mb-2">
                  <div class="mb-3">
                    <div>
                      <label for="exampleFormControlInput1" class="form-label text-dark">開啟智能機台</label>  
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="intelligent" v-bind:id="`${Action}-intelligent-1`" value="1" v-model="Form.intelligent.value">
                      <label class="form-check-label" v-bind:for="`${Action}-intelligent-1`">
                        {{ $t('COMMON.YES') }}
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="intelligent" v-bind:id="`${Action}-intelligent-0`" value="0" v-model="Form.intelligent.value">
                      <label class="form-check-label" v-bind:for="`${Action}-intelligent-0`">
                        {{ $t('COMMON.NO') }}
                      </label>
                    </div>
                    <span class="text-danger">{{ intelligentError }}</span>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow class="mb-3">
                <MDBCol md="12" class="mb-2">
                  <div class="mb-3">
                    <div>
                      <label for="exampleFormControlInput1" class="form-label text-dark">{{ $t('COMMON.TAG') }}</label>  
                    </div>
                    <template  v-for="(tag, k) in tags" :key="k">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" name="tag" v-bind:id="`${Action}-tag-${k}`" v-bind:value="tag.value" v-model="Form.tags.value">
                        <label class="form-check-label" v-bind:for="`${Action}-tag-${k}`">
                          {{ tag.text }}
                        </label>
                      </div>
                    </template>
                    <span class="text-danger">{{ tagsError }}</span>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBCardText>
        <MDBBtn v-if="(Action == 'insert')" color="primary" type="submit" :disabled="SubmitLoading">
          {{ $t("COMMON.INSERT") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn v-else-if="(Action == 'update')" color="primary" type="submit" :disabled="SubmitLoading">
          {{ $t("COMMON.UPDATE") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn type="button" @click="CloseModel()">{{ $t("COMMON.CANCEL") }}</MDBBtn>
      </form>
    </MDBCardBody>
  </MDBCard>
</template>

<script>
import { MDBRow, MDBCard, MDBCardHeader, MDBCardBody, MDBCardText, MDBBtn, MDBCol } from 'mdb-vue-ui-kit';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { computed, inject, ref } from "vue";
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';
import { mapGetters } from 'vuex';

export default {
  name: "MemberForm",
  components: {
    MDBRow,
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBCardText,
    MDBBtn,
    MDBCol
  },
  props: {
    action: {
      type: String,
      default: ""
    },
    model: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String,
      default: ""
    }
  },
  inject:["tags"],
  setup(props, { emit }) {
    const Model = computed({
      get: () => props.model,
      set: val => emit("update:model", val)
    });

    const Action = computed({
      get: () => props.action,
      set: val => emit("update:action", val)
    });

    const MemberUUid = computed({
      get: () => props.uuid,
      set: val => emit("update:uuid", val),
    });

    const GetTable = inject("GetTable");
    const SubmitLoading = ref(false);
    const i18n = useI18n();

    const basicSchema = {
      country: yup.string().required().label("國家"),
      lang: yup.string().required().label("語系"),
      last_name: yup.string().required().label("姓"),
      first_name: yup.string().required().label("名"),
      refer: yup.string().label(i18n.t("COMMON.REFER")),
      dealer: yup.number().required().label(i18n.t("COMMON.DEALERSTATUS")),
      tags: yup.array().required().label(i18n.t("COMMON.TAG")),
      phone: yup.string().label("手機"),
      unit: yup.string().label("公司/學校名稱"),
      postal_code: yup.string().label("郵遞區號"),
    };

    let ExtendSchema = {};
    if(Action.value == "insert") {
      ExtendSchema = {
        email: yup.string().required().email().label(i18n.t("COMMON.EMAIL")),
        password: yup.string().required().min(6).max(32).label(i18n.t("COMMON.PASSWORD")),
        password_confirmation: yup.string().required().min(6).max(32).oneOf([yup.ref('password'), null], 'Passwords must match').label(i18n.t("COMMON.PASSWORDCONFIRM"))
      };  
    }else{
      ExtendSchema = {
        intelligent: yup.number().label('智能機台'),
        telephone: yup.string().label("聯絡電話"),
        birthday: yup.string().label("出生年月日"),
        industry: yup.string().label("行業別"),
        profession: yup.string().label("職業別"),
        title: yup.string().label("職稱"),
        website: yup.string().label("網站"),
        app: yup.string().label("APP"),
        account: yup.string().label("帳號"),
        province: yup.string().label("省"),
        city: yup.string().label("市"),
        area: yup.string().label("鄉鎮"),
        address: yup.string().label("詳細地址"),
        sex: yup.number().label(i18n.t("COMMON.SEX")),
        status: yup.number().required().label(i18n.t("COMMON.STATUS")),
        password: yup.string().max(32).label(i18n.t("COMMON.PASSWORD")),
        password_confirmation: yup.string().max(32).oneOf([yup.ref('password'), null], 'Passwords must match').label(i18n.t("COMMON.PASSWORDCONFIRM"))
      };
    }
    
    const MemberSchema = yup.object(Object.assign(basicSchema,ExtendSchema));

    const { handleSubmit, resetForm, errors, isSubmitting } = useForm({
      validationSchema: MemberSchema,
      initialValues: {
        country: "",
        tags: [],
        refer: "",
        email: "",
        dealer: 0,
        intelligent: 0,
        sex: 1,
        name: "",
        status: 1,
        password: "",
        password_confirmation: "",
        lang: "",
        first_name: "",
        last_name: "",
        phone: "",
        telephone: "",
        birthday: "",
        industry: "",
        profession: "",
        unit: "",
        title: "",
        website: "",
        app: "",
        account: "",
        province: "",
        city: "",
        area: "",
        postal_code: "",
        address: "",
      }
    });

    const { value: refer, errorMessage: referError } = useField('refer');
    const { value: dealer, errorMessage: dealerError } = useField('dealer');
    const { value: name, errorMessage: nameError } = useField('name');
    const { value: email, errorMessage: emailError } = useField('email');
    const { value: sex, errorMessage: sexError } = useField('sex');
    const { value: tags, errorMessage: tagsError } = useField('tags');
    const { value: status, errorMessage: statusError } = useField('status');
    const { value: country, errorMessage: countryError } = useField('country');
    const { value: lang, errorMessage: langError } = useField('lang');
    const { value: first_name, errorMessage: first_nameError } = useField('first_name');
    const { value: last_name, errorMessage: last_nameError } = useField('last_name');
    const { value: phone, errorMessage: phoneError } = useField('phone');
    const { value: telephone, errorMessage: telephoneError } = useField('telephone');
    const { value: birthday, errorMessage: birthdayError } = useField('birthday');
    const { value: industry, errorMessage: industryError } = useField('industry');
    const { value: profession, errorMessage: professionError } = useField('profession');
    const { value: unit, errorMessage: unitError } = useField('unit');
    const { value: title, errorMessage: titleError } = useField('title');
    const { value: website, errorMessage: websiteError } = useField('website');
    const { value: app, errorMessage: appError } = useField('app');
    const { value: account, errorMessage: accountError } = useField('account');
    const { value: province, errorMessage: provinceError } = useField('province');
    const { value: city, errorMessage: cityError } = useField('city');
    const { value: area, errorMessage: areaError } = useField('area');
    const { value: postal_code, errorMessage: postal_codeError } = useField('postal_code');
    const { value: address, errorMessage: addressError } = useField('address');
    const { value: password, errorMessage: passwordError } = useField('password');
    const { value: intelligent, errorMessage: intelligentError } = useField('intelligent');
    const { value: password_confirmation, errorMessage: password_confirmationError } = useField('password_confirmation');

    let Submit;

    if(Action.value == "insert") {
      Submit = handleSubmit(values => {
        SubmitLoading.value = true;
        ApiService.post("/Admin/v1/Member", values).then(response => {
          SubmitLoading.value = false;
          if (response.status == 200) {
            CommonService.AxiosHandle(response);
            resetForm();
            Model.value = false;
            GetTable();
          }
        });
        return false;
      });
    }else{
      Submit = handleSubmit(values => {
        SubmitLoading.value = true;
        ApiService.update("/Admin/v1/Member",props.uuid ,values).then(response => {
          SubmitLoading.value = false;
          if (response.status == 200) {
            CommonService.AxiosHandle(response);
            resetForm();
            Model.value = false;
            MemberUUid.value = "";
            GetTable();
          }
        });
        return false;
      });
    }

    return {
      Model,
      Action,
      Form: {
        refer,
        dealer,
        name,
        email,
        sex,
        status,
        password,
        password_confirmation,
        tags,
        country,
        lang,
        first_name,
        last_name,
        phone,
        telephone,
        birthday,
        industry,
        profession,
        unit,
        title,
        website,
        app,
        account,
        province,
        city,
        area,
        postal_code,
        address,
        intelligent,
      },
      nameError,
      emailError,
      sexError,
      tagsError,
      passwordError,
      password_confirmationError,
      referError,
      dealerError,
      statusError,
      countryError,
      langError,
      first_nameError,
      last_nameError,
      phoneError,
      telephoneError,
      birthdayError,
      industryError,
      professionError,
      unitError,
      titleError,
      websiteError,
      appError,
      accountError,
      provinceError,
      cityError,
      areaError,
      postal_codeError,
      addressError,
      intelligentError,
      Submit,
      MemberUUid,
      resetForm,
      SubmitLoading,
      errors,
      isSubmitting ,
    };
  },
  watch: {
    uuid: function(val){
      if(val){
        this.GetDetail();  
      }
    },
    isSubmitting : function() {
      let fields = Object.keys(this.errors);
      if(fields.length > 0) {
        var element = document.querySelector(`[data-tag="${this.Action}-${fields[0]}"]`);
        if(element) {
          var top = element.offsetTop;
          document.querySelector('main').scrollTo(0,top);
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      "getLang",
      "getCountry",
      "getIndustry",
      "getProfession",
      "getLangList",
      "getApp"
    ]),
  },
  methods: {
    GetDetail() {
      Promise.all([
        new Promise(resolve => {
          ApiService.get("/Admin/v1/Member", this.uuid).then(response => {
            if (response.status == 200) {
              this.resetForm();
              this.Form.tags.value = [];
              for (let k in response.data.data) {
                if(typeof this.Form[k] != "undefined" && response.data.data[k] != null) {
                  switch (k) {
                    case "refer":
                      this.Form.refer.value = response.data.data.refer.serial.toString();
                    break;
                    case "tags":
                      response.data.data.tags.map(item => {
                        this.Form.tags.value.push(item.uuid);
                      });
                    break;
                    default:
                      this.Form[k].value = response.data.data[k].toString();
                    break;
                  }
                }else{
                  switch (k) {
                    case "settings":
                      response.data.data.settings.map((item)=>{
                        switch (item.key) {
                          case "intelligent":
                            this.Form[item.key].value = item.value;
                            break;
                        
                        }
                      });
                    break;
                  }
                }
              }
              this.Model = true;
              resolve(response);
            }
          });
        })
      ]);
    },
    CloseModel() {
      this.MemberUUid = "";
      this.Model = false;
    }
  },
};
</script>